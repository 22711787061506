import React from 'react';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { MenuItem, Menu, useTheme, Grid, useMediaQuery, Typography, Link, List, ListItem, ListItemText, IconButton, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { Paper, Container } from '@material-ui/core';
import logoLight from '../assets/img/logo-zendy-wb.png'
import logoDark from '../assets/img/logo-zendy-db.png'
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import { Link as RouterLink } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { MenuDrawer } from './menuDrawer';
import { useAuth, useContext } from '../context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logoContainer: {
      flexGrow: 1,
    },
    logo: {
      height: 65,
      maxWidth: '100%'
    },
    container: {
      flexGrow: 1,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(6)
    },
    footerContainer: {
      backgroundColor: 'rgb(207,14,16)',
      background: 'linear-gradient(180deg, rgba(207,14,16,1) 50%, rgba(151,0,13,1) 100%)'
    },
    footerWrapper: {
      paddingTop: theme.spacing(10),
    },
    subFooterWrapper: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(4)
    },
    footerText: {
      color: theme.palette.primary.contrastText
    },
    toolBarContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 8
    },
    toolbar: {
      minHeight: 100,
    },
    socialIconsBox: {
      backgroundColor: '#C4C4C41A',
      borderRadius: 8,
      display: 'flex',
      width: 250,
      justifyContent: 'space-around',
      padding: 3
    },
    footerLogo: {
      maxWidth: 200,
    },
    signupButton: {
      backgroundColor: theme.palette.primary.dark
    }
  }),
);

const StyledButton = withStyles({
  label: {
    textTransform: 'none',
    fontSize: 16
  },
})(Button);

const StyledRoundButton = withStyles({
  root: {
    borderRadius: 20,
    paddingLeft: 25,
    paddingRight: 25
  },
  label: {
    fontSize: 16,
    textTransform: 'none',
  },
})(Button);

const LanguageButton = () => {
  const { t, i18n } = useTranslation('layout');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openLanguageMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeLanguageMenu = () => {
    setAnchorEl(null);
  };

  const setLanguage = (language: 'en' | 'es') => {
    i18n.changeLanguage(language);
    closeLanguageMenu();
  }

  return (
    <>
      <StyledButton aria-controls='language-menu' aria-haspopup='true' onClick={openLanguageMenu}>
        {i18n.language === 'en' &&
          'EN'
        }
        {i18n.language === 'es' &&
          'ES'
        }
        <ExpandMoreIcon fontSize='small' />
      </StyledButton>
      <Menu
        id='language-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeLanguageMenu}
      >
        <MenuItem onClick={() => setLanguage('en')}>{t('ENGLISH')}</MenuItem>
        <MenuItem onClick={() => setLanguage('es')}>{t('SPANISH')}</MenuItem>
      </Menu>
    </>
  );
};

export const Layout = ({ children }: { children: any }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('layout');
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const context = useContext();
  const auth = useAuth();

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const [cookiesAccepted, setCookiesAccepted] = React.useState<string | null>(localStorage.getItem('cookies_accepted'));


  const { locale } = (() => {
    if (location.search) {
      const query = new URLSearchParams(location.search);
      return {
        locale: query.get('locale') === 'en' ? 'en' : 'es'
      };
    } else {
      return {
        locale: 'es'
      }
    }
  })();

  React.useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  const handleScroll = () => {
    if (window.scrollY > 150) {
      acceptCookies();
      window.removeEventListener('scroll', handleScroll);
    }
  }

  React.useEffect(() => {
    if (cookiesAccepted) {
      localStorage.setItem('cookies_accepted', cookiesAccepted);
      window.removeEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('scroll', handleScroll);
    }

  }, [cookiesAccepted]);

  const acceptCookies = () => {
    setCookiesAccepted(new Date().toISOString());
  }

  return (
    <div className={classes.root}>
      <MetaTags>
        <title>{t('TITLE')}</title>
        <meta name='description' content={t('DESCRIPTION')} />
      </MetaTags>
      <MenuDrawer open={isSm ? drawerOpen : false} onClose={() => { setDrawerOpen(false) }} />
      <AppBar position='static' color='transparent' variant='outlined'>
        <Container className={classes.toolBarContainer}>
          <Toolbar className={classes.toolbar}>
            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={10} md={4}>
                <Grid container justify={'flex-start'}>
                  <Grid item>
                    <Link href='#'>
                      <img className={classes.logo} src={logoLight} />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              {!isSm &&
                <Grid item md={8}>
                  <Grid container justify={'flex-end'} spacing={2}>
                    <Grid item>
                      <StyledButton href={i18n.language === 'es' ? 'https://app.zendy.tel' : 'https://app.zendy.tel'} color='inherit'>{t('START')}</StyledButton>
                    </Grid>
                    <Grid item>
                      <StyledButton component={RouterLink} to='/transfer' color='inherit'>{t('NEW TRANSFER')}</StyledButton>
                    </Grid>
                    {(window.location.pathname.includes('/signup/profile') || window.location.pathname.includes('/signup/kyc')) &&
                      <Grid item>
                        <StyledRoundButton color='primary' variant='outlined' onClick={() => {
                          auth.logout();
                        }}>{t('LOGOUT')}</StyledRoundButton>
                      </Grid>
                    }
                    {!(window.location.pathname.includes('/signup/profile') || window.location.pathname.includes('/signup/kyc')) &&
                      <Grid item>
                        <StyledRoundButton component={RouterLink} to='/account' color='primary' variant='outlined'>{context.data.auth.isSignedIn ? t('MY ACCOUNT') : t('LOGIN')}</StyledRoundButton>
                      </Grid>
                    }
                    {!context.data.auth.isSignedIn &&
                      <Grid item>
                        <StyledRoundButton className={classes.signupButton} component={RouterLink} to='/signup' color='primary' variant='contained'>{t('SIGNUP')}</StyledRoundButton>
                      </Grid>
                    }
                    <Grid item>
                      <LanguageButton />
                    </Grid>
                  </Grid>
                </Grid>
              }
              {isSm &&
                <Grid item xs={2} md={8}>
                  <Grid container justify={'flex-end'} spacing={2}>
                    <Grid item>
                      <IconButton color='inherit' onClick={() => setDrawerOpen(true)}><MenuIcon /></IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <div className={classes.container}>
        {children}
      </div>
      <Paper className={classes.footerContainer} variant='outlined' square={true}>
        <Container className={classes.footerWrapper}>
          <Grid container spacing={2}>
            <Grid item direction='column' md={6} sm={12} container justify='flex-start' alignContent={isSm ? 'center' : 'flex-start'} alignItems={isSm ? 'center' : 'flex-start'}>
              <Grid item>
                <List dense>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://zendy.tel/terms' : 'https://zendy.tel/terms'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('TERMS_CONDITIONS')} />
                  </ListItem>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://zendy.tel/privacy' : 'https://zendy.tel/privacy'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('PRIVACY_POLICY')} />
                  </ListItem>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://zendy.tel/cookies' : 'https://zendy.tel/cookies'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('COOKIES_LINK')} />
                  </ListItem>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://zendy.tel/legal' : 'https://zendy.tel/legal'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('LEGAL_INFO')} />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid item container md={3} sm={12}>
              <Grid container justify='center' alignContent='center' alignItems='center' spacing={4}>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Grid item>
                      <img className={classes.footerLogo} src={logoDark} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Grid item>
                      <Box className={classes.socialIconsBox}>
                        <IconButton component='a' rel='noopener' target='_blank' href='https://www.facebook.com' aria-label='facebook'>
                          <FacebookIcon className={classes.footerText} />
                        </IconButton>
                        <IconButton component='a' rel='noopener' target='_blank' href='https://www.youtube.com' aria-label='youtube'>
                          <YoutubeIcon className={classes.footerText} />
                        </IconButton>
                        <IconButton component='a' rel='noopener' target='_blank' href='https://www.instagram.com' aria-label='instagram'>
                          <InstagramIcon className={classes.footerText} />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Grid container justify='center' alignContent='center'>
          <Grid item xs={12}>
            <Box className={classes.subFooterWrapper}>
              <Typography className={classes.footerText} style={{ fontSize: 16 }} align='center'>
                {t('SUBFOOTER')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
};

