import React from 'react';
import { Box, Button, Drawer, Grid, IconButton, makeStyles, Menu, MenuItem, Typography, withStyles } from '@material-ui/core';
import drawerLogo from '../assets/img/logo-zendy-db.png';
import CloseIcon from '@material-ui/icons/Close';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import { useAuth, useContext } from '../context';
import LanguageIcon from '@material-ui/icons/Translate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: 'rgb(207,14,16)',
    background: 'linear-gradient(180deg, rgba(207,14,16,1) 50%, rgba(151,0,13,1) 100%)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  logo: {
    maxWidth: '100%',
    height:85,
  },
  closeIcon: {
    color: theme.palette.primary.contrastText,
    cursor: 'pointer'
  },
 
  upperMenu: {
    flex: 4,
  },
  lowerMenu: {
    flex: 1,
  },
  icon: {
    color: theme.palette.primary.contrastText
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  drawerPaper: {
    height: '100%',
  }
}));


const LanguageButton = () => {
  const { t, i18n } = useTranslation('layout');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openLanguageMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeLanguageMenu = () => {
    setAnchorEl(null);
  };

  const setLanguage = (language: 'en' | 'es') => {
    i18n.changeLanguage(language);
    closeLanguageMenu();
  }

  const getCurrentLanguage = () => {
    if (i18n.language.includes('es')) {
      return t('SPANISH');
    } else {
      return t('ENGLISH');
    }
  }

  return (
    <>
      <StyledButton aria-controls='language-menu' aria-haspopup='true' onClick={openLanguageMenu}>
        <LanguageIcon style={{ marginRight: 10 }} />
        {getCurrentLanguage()}
        <ExpandMoreIcon fontSize='small' />
      </StyledButton>
      <Menu
        id='language-menu-drawer'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeLanguageMenu}
      >
        <MenuItem onClick={() => setLanguage('en')}>{t('ENGLISH')}</MenuItem>
        <MenuItem onClick={() => setLanguage('es')}>{t('SPANISH')}</MenuItem>
      </Menu>
    </>
  );
};

const StyledButton = withStyles(theme => ({
  label: {
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    fontSize: 30,
    textAlign: 'center'
  },
}))(Button);

export const MenuDrawer = ({
  open,
  onClose
}: {
  open: boolean,
  onClose: () => void
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('layout');
  const context = useContext();
  const auth = useAuth();

  return (
    <Drawer
      anchor='top'
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
      onClose={() => onClose()}>
      <Box className={classes.main}>
        <Grid container className={classes.upperMenu} direction='row' spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <Grid item xs={6}>
                <img className={classes.logo} src={drawerLogo} />
              </Grid>
              <Grid container item xs={6} justify='flex-end'>
                <IconButton onClick={() => onClose()}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction='row' spacing={2}>
              <Grid container item xs={12} justify='center'>
                <LanguageButton />
              </Grid>
              <Grid container item xs={12} justify='center'>
                <StyledButton onClick={() => onClose()} href={i18n.language==='es'?'https://app.zendy.tel':'https://app.zendy.tel'} color='inherit'>{t('START')}</StyledButton>
              </Grid>
              <Grid container item xs={12} justify='center'>
                <StyledButton onClick={() => { onClose() }} component={RouterLink} to='/transfer' color='inherit'>{t('NEW TRANSFER')}</StyledButton>
              </Grid>
              {(window.location.pathname.includes('/signup/profile') || window.location.pathname.includes('/signup/kyc')) &&
                <Grid container item xs={12} justify='center'>
                  <StyledButton color='inherit' onClick={() => {
                    auth.logout();
                    onClose();
                  }}>{t('LOGOUT')}
                  </StyledButton>
                </Grid>
              }
              {!(window.location.pathname.includes('/signup/profile') || window.location.pathname.includes('/signup/kyc')) &&
                <Grid container item xs={12} justify='center'>
                  <StyledButton component={RouterLink} to='/account' onClick={()=>onClose()} color='inherit'>
                    {context.data.auth.isSignedIn?t('MY ACCOUNT'):t('LOGIN')}
                  </StyledButton>
                </Grid>
              }
              {!context.data.auth.isSignedIn &&
                <Grid container item xs={12} justify='center'>
                  <StyledButton onClick={() => onClose()} component={RouterLink} to='/signup' color='inherit'>
                    {t('SIGNUP')}
                  </StyledButton>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.lowerMenu} alignItems='center'>
          <Grid item xs={12}>
            <Box className={classes.iconBox}>
              <IconButton component='a' rel='noopener' target='_blank' href='https://www.facebook.com' aria-label='facebook'>
                <FacebookIcon className={classes.icon} />
              </IconButton>
              <IconButton component='a' rel='noopener' target='_blank' href='https://www.youtube.com' aria-label='youtube'>
                <YoutubeIcon className={classes.icon} />
              </IconButton>
              <IconButton component='a' rel='noopener' target='_blank' href='https://www.instagram.com' aria-label='instagram'>
                <InstagramIcon className={classes.icon} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

      </Box>
    </Drawer>
  );
}